import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { ILayoutConf, LayoutService } from 'app/shared/services/layout.service';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { CustomerService } from 'app/views/customer/customer.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PasswordResetadminComponent } from 'app/views/admin/password-resetadmin/password-resetadmin.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public customerItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  private customerItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  role;
  user11;
  user;
  customer;
  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService,
    private ls: LocalStoreService,
    private crudService: CustomerService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private loader: AppLoaderService
  ) {}

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter((item) => item.type === 'icon').length;
    });
    this.customerItemsSub = this.navService.customerItems$.subscribe((menuItem) => {
      this.customerItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter((item) => item.type === 'icon').length;
    });

    this.layoutConf = this.layout.layoutConf;
    this.user = this.jwtAuth.getUser();
    this.role = this.user.role;
    if (this.user.role == 'customer') {
      this.user11 = this.jwtAuth.getUser11();
      this.customer = this.jwtAuth.getCustomer();
      this.crudService.getCustomerById().subscribe((res: any) => {
        if (res) {
          this.customer = res['data']['customer'][0];
          this.ls.setItem('customer', res['data']['customer']);
          this.ls.setItem('user11', res['data']['user']);
        }
      });
    }
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
    if (this.customerItemsSub) {
      this.customerItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }
  signOut() {
    this.jwtAuth.signout();
  }
  openresetpasswordmodal() {
    let title = 'Reset Password';
    let dialogRef: MatDialogRef<any> = this.dialog.open(PasswordResetadminComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        // If user press cancel
        return;
      }
      this.loader.open();
      this.jwtAuth.changePasswordAdmin(res.oldpassword, res.newpassword).subscribe(
        (res) => {
          this.snack.open('Password Updated Successfully', 'OK', { duration: 4000 });
          this.loader.close();
        },
        (err) => {
          this.snack.open(`${err.error.message}`, 'OK', { duration: 4000 });
          this.loader.close();
        }
      );
    });
  }
}
