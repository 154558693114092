import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  apiUrl: any = environment.apiURL;
  pendingDRCount = 0;
  customerMenu: IMenuItem[] = [
    // {
    //   name: 'Analytics',
    //   type: 'link',
    //   icon: 'list',
    //   state: 'customer/analytics',
    // },
    {
      name: 'My Profile',
      type: 'link',
      icon: 'list',
      state: 'customer/my-profile',
    },
    {
      name: 'Cargo Receipt',
      type: 'link',
      icon: 'list',
      state: 'customer/cargo-receipt',
    },
    {
      name: 'Cargo Gate Pass',
      type: 'link',
      icon: 'list',
      state: 'customer/cargo-gatepass',
    },
    {
      name: 'Inbound / Outbound Transaction',
      type: 'link',
      icon: 'receipt',
      state: 'customer/balance-sheet',
    },
    {
      name: 'Inbound Stock Sku Wise',
      type: 'link',
      icon: 'receipt',
      state: 'customer/inbound-sku',
    },

    {
      name: 'Inbound Stock All Item',
      type: 'link',
      icon: 'receipt',
      state: 'customer/inboundsku-allitems',
    },

    {
      name: 'Delivery Requests',
      type: 'link',
      icon: 'list',
      state: 'customer/delivery-requests',
    },
    {
      name: 'Invoice',
      type: 'link',
      icon: 'receipt',
      state: 'customer/finance',
    },
    {
      name: 'Notices',
      type: 'link',
      icon: 'receipt',
      state: 'customer/notices',
    },
    {
      name: 'Bookings',
      type: 'link',
      icon: 'receipt',
      state: 'customer/facility-map',
    },
    // {
    //   name: 'Finance Sheet',
    //   type: 'link',
    //   icon: 'receipt',
    //   state: 'customer/customer-finance',
    // },
  ];
  iconMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'dashboard',
      // state: 'admin',
      sub: [{ name: 'Analytics', state: 'admin/analytics' }],
    },
    {
      name: 'Operations',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'dashboard',
      // state: 'admin',
      sub: [
        {
          name: 'Facility Map',
          type: 'link',
          icon: 'list',
          state: 'admin/facility-map',
        },
        {
          name: 'Drivers',
          type: 'link',
          icon: 'list',
          state: 'admin/drivers',
        },
        {
          name: 'Task',
          type: 'link',
          icon: 'receipt',
          state: 'admin/task',
        },
        {
          name: 'Lead',
          type: 'link',
          icon: 'receipt',
          state: 'admin/lead',
        },
      ],
    },

    {
      name: 'Day To Day',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'dashboard',
      // state: 'admin',
      sub: [
        {
          name: 'Cargo Receipt',
          type: 'link',
          icon: 'list',
          state: 'admin/list',
        },
        {
          name: 'Cargo Gate Pass',
          type: 'link',
          icon: 'list',
          state: 'admin/gatepasslist',
        },
        {
          name: 'Inbound Stock SKU Wise',
          type: 'link',
          icon: 'list',
          state: 'admin/inboundbysku-list',
        },
        {
          name: 'Inbound Stock All Items',
          type: 'link',
          icon: 'list',
          state: 'admin/inboundsku-allitems',
        },
        // {
        //   name: 'Outbound Stock SKU Wise',
        //   type: 'link',
        //   icon: 'list',
        //   state: 'admin/outboundbysku-list',
        // },
        {
          name: 'Invoice',
          type: 'link',
          icon: 'receipt',
          state: 'admin/finance',
        },
        {
          name: 'Notice',
          type: 'link',
          icon: 'receipt',
          state: 'admin/notice',
        },
      ],
    },
    {
      name: 'Users',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'dashboard',
      // state: 'admin',
      sub: [
        {
          name: 'Customer',
          type: 'link',
          icon: 'list',
          state: 'admin/customer',
        },
        {
          name: 'Inbound / Outbound Transactions',
          type: 'link',
          icon: 'receipt',
          state: 'admin/balance-sheet',
        },
        // {
        //   name: 'Inbound / Outbound Transactions By Sku Wise',
        //   type: 'link',
        //   icon: 'receipt',
        //   state: 'admin/balance-sheetsku',
        // },
        {
          name: 'Delivery Requests',
          type: 'link',
          icon: 'list',
          state: 'admin/delivery-request',
        },
        {
          name: 'Invited Customers',
          type: 'link',
          icon: 'receipt',
          state: 'admin/invitedcustomers',
        },
      ],
    },

    // {
    //   name: 'Templates',
    //   type: 'link',
    //   icon: 'list',
    //   state: 'admin/template',
    // },
    // {
    //   name: 'ADMIN',
    //   type: 'dropDown',
    //   tooltip: 'Admin',
    //   icon: 'dashboard',
    //   state: 'admin',
    //   sub: [
    //     { name: 'Analytics', state: 'analytics' },
    //     { name: 'Customer', state: 'customer' },
    //     { name: 'Cargo Receipt', state: 'list' },
    //     { name: 'Cargo Gate Pass', state: 'gatepasslist' },
    //     { name: 'Drivers', state: 'drivers' },
    //     // {name: 'Learning Management', state: 'learning-management'},
    //     // { name: 'Analytics Alt', state: 'analytics-alt' },
    //     // { name: 'Cryptocurrency', state: 'crypto' },
    //     // { name: 'Dark Cards', state: 'dark' }
    //   ]
    // },
    // {
    //   name: 'DOC',
    //   type: 'extLink',
    //   tooltip: 'Documentation',
    //   icon: 'library_books',
    //   state: 'http://demos.ui-lib.com/egret-doc/'
    // }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  customerItems = new BehaviorSubject<IMenuItem[]>(this.customerMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  customerItems$ = this.customerItems.asObservable();
  constructor(private http: HttpClient) {
    // this.getPendingDR();
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  async getPendingDR() {
    this.http.get(`${this.apiUrl}/api/deliveryrequest/getDeliveryRequestCount`).subscribe((res: any) => {
      this.pendingDRCount = res.resData.count;
      debugger;
      return res.resData.count;
    });
  }
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
    // this.menuItems.next(this.separatorMenu);
    // break;
    //   case 'icon-menu':
    this.menuItems.next(this.iconMenu);
    this.customerItems.next(this.customerMenu);
    // break;
    //   default:
    // this.menuItems.next(this.plainMenu);
    // }
  }
}
