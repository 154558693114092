<h1 matDialogTitle>{{ data.title }}</h1>
<!-- {{ 'ADD' }} {{ 'MEMBER' }} -->
<form [formGroup]="itemForm" (ngSubmit)="submit()">
  <div fxLayout="row wrap" fxLayout.lt-sm="column">
    <div fxFlex="50" class="pr-16">
      <mat-form-field class="full-width">
        <input
          matInput
          name="name"
          type="email"
          [formControl]="itemForm.controls['oldpassword']"
          placeholder="Old Password"
          required="true"
          type="{{ typePassword }}"
        />
        <mat-icon style="float: right" color="primary" (click)="changePasswordText()">remove_red_eye</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="50" class="pr-16">
      <mat-form-field class="full-width">
        <input
          matInput
          name="name"
          type="email"
          [formControl]="itemForm.controls['newpassword']"
          placeholder="New Password"
          required="true"
          type="{{ typePassword }}"
        />
        <mat-icon style="float: right" color="primary" (click)="changePasswordText()">remove_red_eye</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="50" class="pr-16">
      <mat-form-field class="full-width">
        <input
          matInput
          name="name"
          type="email"
          [formControl]="itemForm.controls['confirmpassword']"
          placeholder="Confirm Password"
          required="true"
          type="{{ typePassword }}"
        />
        <mat-icon style="float: right" color="primary" (click)="changePasswordText()">remove_red_eye</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="100" class="mt-16">
      <button mat-button color="warn" type="button" (click)="dialogRef.close(false)">Cancel</button>
      <span fxFlex></span>
      <button mat-raised-button color="primary" (click)="submit()">
        {{ 'SAVE' }}
      </button>
    </div>
  </div>
</form>
