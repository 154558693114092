<footer class="main-footer">
  <div class="container-dynamic d-flex">
    <!-- <a
      mat-raised-button
      color="accent"
      href="http://1.envato.market/JN1r7"
      >Buy Egret</a
    > -->
    <span class="m-auto"></span>
    <!-- Design & Developed by &nbsp;<a href="https://ui-lib.com">Warehouse Management</a> -->
    &copy; Copyrights 2023. All Rights Reserved.
  </div>
</footer>
