<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">
      <!-- App Logo -->
      <!-- pallete-scss $color500 -->
      <div class="branding" style="background-color: #fff !important">
        <img src="assets/images/RGSL-LOGO.png" alt="" class="app-logo" />
        <!-- <span class="app-logo-text">WMS</span> -->

        <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
        <!-- <div
          class="sidebar-compact-switch"
          [ngClass]="{ active: layoutConf.sidebarCompactToggle }"
          (click)="toggleCollapse()"
          *ngIf="layoutConf.sidebarStyle !== 'compact'"
        >
          <span></span>
        </div> -->
      </div>
      <!-- Sidebar user -->
      <div class="app-user">
        <div class="app-user-photo">
          <img src="{{ user11?.avatar }}" class="mat-elevation-z1" alt="avatar of user" *ngIf="role == 'customer'" />
          <img
            src="assets/images/face-7.jpg"
            class="mat-elevation-z1"
            alt="avatar of user"
            *ngIf="role != 'customer'"
          />
        </div>
        <span class="app-user-name mb-8" *ngIf="role == 'customer'">
          <!-- <mat-icon class="icon-xs text-muted">lock</mat-icon> -->
          {{ customer?.name }}
        </span>
        <span class="app-user-name mb-8" *ngIf="role != 'customer'">
          <!-- <mat-icon class="icon-xs text-muted">lock</mat-icon> -->
          {{ user?.userName }}
        </span>
        <!-- Small buttons -->
        <div class="app-user-controls">
          <!-- <button
            class="text-muted"
            mat-icon-button
            mat-xs-button
            [matMenuTriggerFor]="appUserMenu"
            matTooltip="Change Password"
          >
            <mat-icon>settings</mat-icon>
          </button> -->
          <button
            class="text-muted"
            mat-icon-button
            mat-xs-button
            matTooltip="Reset Password"
            (click)="openresetpasswordmodal()"
            *ngIf="role == 'admin'"
          >
            <mat-icon>lock</mat-icon>
          </button>
          <button class="text-muted" mat-icon-button mat-xs-button matTooltip="Sign Out" (click)="signOut()">
            <mat-icon>exit_to_app</mat-icon>
          </button>
          <mat-menu #appUserMenu="matMenu">
            <button mat-menu-item routerLink="/profile/overview">
              <mat-icon>account_box</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item routerLink="/profile/settings">
              <mat-icon>settings</mat-icon>
              <span>Account Settings</span>
            </button>
            <button mat-menu-item routerLink="/calendar">
              <mat-icon>date_range</mat-icon>
              <span>Calendar</span>
            </button>
            <button mat-menu-item (click)="jwtAuth.signout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <!-- Navigation -->
      <app-sidenav
        *ngIf="role == 'admin'"
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
      <app-sidenav
        *ngIf="
          role == 'customer' && customer?.applicationStatus != 'partial' && customer?.applicationStatus != 'rejected'
        "
        [items]="customerItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
    </div>
  </div>
</div>
