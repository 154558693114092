import { Injectable } from '@angular/core';
import { UserDB } from '../../shared/inmemory-db/users';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CustomerService {
  items: any[];
  apiUrl: any = environment.apiURL;
  JWT_TOKEN = 'JWT_TOKEN';
  APP_USER = 'EGRET_USER';
  headers: any;
  cId;
  constructor(private http: HttpClient, private ls: LocalStoreService) {
    let userDB = new UserDB();
    this.items = userDB.users;
    if (this.cId) {
      this.cId = this.getCustomer().id;
    }
  }

  //******* Implement your APIs ********
  getItems(): Observable<any> {
    return of(this.items.slice());
  }
  addItem(item): Observable<any> {
    item._id = Math.round(Math.random() * 10000000000).toString();
    this.items.unshift(item);
    return of(this.items.slice()).pipe(delay(1000));
  }
  updateItem(id, item) {
    this.items = this.items.map((i) => {
      if (i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    });
    return of(this.items.slice()).pipe(delay(1000));
  }
  removeItem(row) {
    let i = this.items.indexOf(row);
    this.items.splice(i, 1);
    return of(this.items.slice()).pipe(delay(1000));
  }
  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem('user');
  }
  getUser11() {
    return this.ls.getItem('user11');
  }

  getCustomer() {
    return this.ls.getItem('customer')[0];
  }
  getInvoiceById() {
    let customer = this.getCustomer();
    debugger;
    if (customer) {
      return this.http.get(`${this.apiUrl}/api/customerInvoice/getInvoiceByCustomer?cId=${customer.id}`);
    } else {
      return this.http.get(`${this.apiUrl}/api/customerInvoice/getInvoiceByCustomer?cId=${this.cId}`);
    }
  }
  getCustomerFinanceSheetById(id) {
    return this.http.get(`${this.apiUrl}/api/`);
  }
  getCustomerBalanceSheetById() {
    let customer = this.getCustomer();
    debugger;
    if (customer) {
      return this.http.get(`${this.apiUrl}/api/user/getCustomerBalanceSheet?cId=${customer.id}`);
    } else {
      return this.http.get(`${this.apiUrl}/api/user/getCustomerBalanceSheet?cId=${this.cId}`);
    }
  }
  getCargoReceiptByCustomerId() {
    let customer = this.getCustomer();
    return this.http.get(`${this.apiUrl}/api/cargoreceipt/getReceiptByCustomer?cId=${customer.id}`);
  }
  generateComment(orderNo, text) {
    let user = this.getUser();
    let user11 = this.getCustomer();
    let comment = {
      crId: orderNo,
      userId: user.userId,
      comment: text,
      uploadedBy: user11.name,
      isVisible: true,
    };
    return this.http.post(`${this.apiUrl}/api/cargoreceipt/createComment`, {
      comment,
      name: user11.name,
      cId: user11.id,
    });
  }
  addFiles(crId, url) {
    let files = [];
    let user11 = this.getCustomer();
    for (var i = 0; i < url.length; i++) {
      // file = {
      //   crId: orderNo,
      //   crType: files[i].type,
      //   url: files[i].url,
      // };
      files.push({
        crId: crId,
        crType: '',
        url: url[i].url,
        visible_all: url[i].visible,
        uploadedBy: user11.name,
      });
    }
    return this.http.post(`${this.apiUrl}/api/cargoreceipt/createfile`, { files });
  }

  getCargoGatePassByCustomerId() {
    let customer = this.getCustomer();
    return this.http.get(`${this.apiUrl}/api/gatepass/getPassByCustomer?cId=${customer.id}`);
  }
  generateCommentCargoGatePass(orderNo, text) {
    let user = this.getUser();
    let user11 = this.getCustomer();
    let comment = {
      gpId: orderNo,
      userId: user.userId,
      comment: text,
      uploadedBy: user11.name,
      isVisible: true,
    };
    return this.http.post(`${this.apiUrl}/api/gatepass/createComment`, {
      comment,
      name: user11.name,
      cId: user11.id,
    });
  }
  addFilesCargoGatePass(crId, url) {
    let files = [];
    let user11 = this.getCustomer();
    for (var i = 0; i < url.length; i++) {
      // file = {
      //   crId: orderNo,
      //   crType: files[i].type,
      //   url: files[i].url,
      // };
      files.push({
        crId: crId,
        crType: '',
        url: url[i].url,
        visible_all: url[i].visible,
        uploadedBy: user11.name,
      });
    }
    return this.http.post(`${this.apiUrl}/api/gatepass/createfile`, { files });
  }
  deletCragoGatePass(id) {
    return this.http.delete(`${this.apiUrl}/api/gatepass/deletePass?id=${id}`);
  }
  getAllDeliveryRequestsById() {
    let customer = this.getCustomer();
    return this.http.get(`${this.apiUrl}/api/deliveryrequest/getDeliveryRequestByCustomer?cId=${customer.id}`);
  }
  updateDeliveryRequest(res) {
    let userApp = this.getUser();
    let user = this.getCustomer();
    return this.http.post(`${this.apiUrl}/api/deliveryrequest/updatedeliveryRequest`, {
      cId: res.cId,
      id: res.id,
      generatedBy: res.generatedBy,
      status: res.status,
      qty: res.quantity,
      weight: res.weight,
      contactNo: res.mobile,
      address: res.address,
      truckType: res.vehicletype,
      lat: res.lat,
      lng: res.lng,
      locName: res.pinlocation,
      name: user.name,
    });
  }
  updateDeliveryRequestStatus(id, status) {
    let user = this.getCustomer();
    return this.http.post(`${this.apiUrl}/api/deliveryrequest/updateStatus`, {
      id: id,
      status: status,
      name: user.name,
    });
  }
  changeDeliveryRequestStatus(changetype, changetime, changedate, changelocation, applicationStatus, id) {
    let user = this.getCustomer();
    return this.http.post(`${this.apiUrl}/api/deliveryrequest/changeRequest`, {
      changetype: changetype,
      changeDT: changetime,
      changeDD: changedate,
      changeDL: changelocation,
      applicationStatus: applicationStatus,
      id: id,
      name: user.name,
    });
  }
  updateDeliveryRequestApplicationStatus(id, status, reason) {
    return this.http.post(`${this.apiUrl}/api/deliveryrequest/updateApplicationStatus`, {
      id: id,
      applicationStatus: status,
      reason: reason,
    });
  }
  deleteDeliveryRequest(id) {
    return this.http.delete(`${this.apiUrl}/api/deliveryrequest/deleteRequest?id=${id}`);
  }
  generateDeliveryRequest(res) {
    let user = this.getUser11();
    let customer = this.getCustomer();
    let userApp = this.getUser();
    return this.http.post(`${this.apiUrl}/api/deliveryrequest/createDeliveryRequest`, {
      cId: customer.id,
      generatedBy: customer.name,
      status: res.status,
      qty: res.quantity,
      weight: res.weight,
      truckType: res.vehicletype,
      lat: res.lat,
      lng: res.lng,
      drTo: res.pinlocation,
      locName: res.pinlocation,
      name: customer.name,
      drFrom: res.drFrom,
      drfName: res.drfName,
      drfContact: res.drfContact,
      drtName: res.drtoName,
      drtContact: res.drtoContact,
      drDate: res.drDate,
      timezone: res.timezone,
      price: null,
      pod: '',
      pod1: '',
      pod2: '',
    });
  }
  getCustomerById() {
    let customer = this.getCustomer();
    if (customer) {
      return this.http.get(`${this.apiUrl}/api/user/getUserById?cId=${customer.id}`);
    } else {
      return this.http.get(`${this.apiUrl}/api/user/getUserById?cId=${this.cId}`);
    }
  }
  getCountries() {
    this.headers = new HttpHeaders({
      'X-CSCAPI-KEY': 'VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==',
    });
    return this.http.get<any>(`https://api.countrystatecity.in/v1/countries`, { headers: this.headers });
  }
  getCities(code: any) {
    this.headers = new HttpHeaders({
      'X-CSCAPI-KEY': 'VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==',
    });
    return this.http.get<any>(`https://api.countrystatecity.in/v1/countries/` + code + `/cities`, {
      headers: this.headers,
    });
  }
  getNoticeByCustomerId() {
    let customer = this.getCustomer();
    return this.http.get(`${this.apiUrl}/api/reminder/getNoticebyCustomer?cId=${customer.id}`);
  }
  getShedBookingByCustomerId(id) {
    return this.http.get(`${this.apiUrl}/api/shed/getBookingbyCustomer?cId=${id}`);
  }
  uploadPDF(data: any, email: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.apiUrl}/api/aws/file?email=${email}`, data).subscribe((response: any) => {
        //
        resolve(response);
      }, reject);
    });
  }
  updateUserById(user, customer, userId, picture1, picture2, picture3) {
    let userApp = this.getUser();
    //customer.applicationStatus = 'completed';
    return this.http.post(`${this.apiUrl}/api/user/updateUserById`, {
      user: user,
      customer: customer,
      userId: userId,
      name: userApp.userName,
    });
  }
  uploadContractPDF(url, cId) {
    let userApp = this.getUser();
    return this.http.post(`${this.apiUrl}/api/user/uploadcontract`, { url: url, cId: cId, name: userApp.userName });
  }
  uploadKycPDF(url, cId) {
    let userApp = this.getUser();

    return this.http.post(`${this.apiUrl}/api/user/uploadkyc`, { url: url, cId: cId, name: userApp.userName });
  }
  convertToImage(base64): Observable<File> {
    return Observable.create((observer) => {
      const img = this.createImage(base64);

      setTimeout(() => {
        const elem = document.createElement('canvas');

        const ctx = elem.getContext('2d') as CanvasRenderingContext2D;

        ctx.drawImage(img, 0, 0, 400, 100);

        ctx.canvas.toBlob(
          (blob) => {
            observer.next(
              new File([blob], 'Digital Signature.png', {
                type: 'image/png',

                lastModified: Date.now(),
              })
            );
          },

          'image/png'
        );
      });
    });
  }
  createImage(ev) {
    const imageContent = ev;

    const img = new Image();

    img.src = imageContent;

    return img;
  }
  changePassword(oldPassword, newPassword) {
    let user = this.getUser();
    return this.http.post(`${this.apiUrl}/api/user/resetPassword`, {
      id: user.userId,
      password: oldPassword,
      newpassword: newPassword,
    });
  }
  getAllInboundSkuByCustomerId(id) {
    return this.http.get(`${this.apiUrl}/api/inboundsku/getInboundStockSkuByCustomer?cId=${id}`);
  }

  getCustomerInboundSkuById() {
    let customer = this.getCustomer();
    // if (customer) {
    return this.http.get(`${this.apiUrl}/api/user/getCustomerBalanceSheet?cId=${customer.id}`);
    // } else {
    //   return this.http.get(`${this.apiUrl}/api/user/getCustomerBalanceSheet?cId=${this.cId}`);
    // }
  }
  generateCommentForInboundSku(orderNo, text) {
    let userApp = this.getUser();
    let user = this.getUser();
    let user11 = this.getUser11();
    let comment = {
      inboundStockSkuId: orderNo,
      cId: user.userId,
      comment: text,
      uploadedBy: user11.name,
      isVisible: true,
    };
    return this.http.post(`${this.apiUrl}/api/inboundsku/createComment`, {
      comment,
      name: user11.name,
      cId: user11.id,
    });
  }
}
