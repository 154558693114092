import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError, delay } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { of, BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { CustomerService } from 'app/views/customer/customer.service';

// ================= only for demo purpose ===========
const DEMO_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YjhkNDc4MDc4NmM3MjE3MjBkYzU1NzMiLCJlbWFpbCI6InJhZmkuYm9ncmFAZ21haWwuY29tIiwicm9sZSI6IlNBIiwiYWN0aXZlIjp0cnVlLCJpYXQiOjE1ODc3MTc2NTgsImV4cCI6MTU4ODMyMjQ1OH0.dXw0ySun5ex98dOzTEk0lkmXJvxg3Qgz4ed';

const DEMO_USER: User = {
  id: '5b700c45639d2c0c54b354ba',
  displayName: 'Watson Joyce',
  role: 'SA',
};
// ================= you will get those data from server =======

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = 'JWT_TOKEN';
  APP_USER = 'EGRET_USER';

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public customerService: CustomerService
  ) {
    this.route.queryParams.subscribe((params) => (this.return = params['return'] || '/'));
  }

  public signin(username, password) {
    // return of({token: DEMO_TOKEN, user: DEMO_USER})
    //   .pipe(
    //     delay(1000),
    //     map((res: any) => {
    //       this.setUserAndToken(res.token, res.user, !!res);
    //       this.signingIn = false;
    //       return res;
    //     }),
    //     catchError((error) => {
    //       return throwError(error);
    //     })
    //   );

    // FOLLOWING CODE SENDS SIGNIN REQUEST TO SERVER

    this.signingIn = true;
    return this.http.post(`${environment.apiURL}/api/user/login`, { email: username, password, role: 'user' }).pipe(
      map((res: any) => {
        if (res.role != 'admin') {
          this.customerService.cId = res.cId;
          // this.getUserById(res.cId).subscribe((response) => {
          //   this.ls.setItem('customer', response['data']['customer']);
          //   this.ls.setItem('user11', response['data']['user']);
          // });
        }
        this.setUserAndToken(res.token, res.email, res, !!res);
        this.signingIn = false;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  createUser(email: string, password: string) {
    let params = {
      email: email,
      password: password,
      role: 'user',
    };

    return this.http.post(`${environment.apiURL}/api/user/signup`, params);
  }
  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    return of(DEMO_USER).pipe(
      map((profile: User) => {
        // this.setUserAndToken(this.getJwtToken(), profile, true);
        this.signingIn = false;
        return profile;
      }),
      catchError((error) => {
        return of(error);
      })
    );

    /*
      The following code get user data and jwt token is assigned to
      Request header using token.interceptor
      This checks if the existing token is valid when app is reloaded
    */

    // return this.http.get(`${environment.apiURL}/api/users/profile`)
    //   .pipe(
    //     map((profile: User) => {
    //       this.setUserAndToken(this.getJwtToken(), profile, true);
    //       return profile;
    //     }),
    //     catchError((error) => {
    //       this.signout();
    //       return of(error);
    //     })
    //   );
  }

  public signout() {
    this.setUserAndToken(null, null, null, false);
    localStorage.clear();
    this.router.navigateByUrl('sessions/signin');
  }
  getUserById(cId) {
    return this.http.get(`${environment.apiURL}/api/user/getUserById?cId=${cId}`);
  }
  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem('user');
  }
  getUser11() {
    return this.ls.getItem('user11');
  }
  getCustomer() {
    return this.ls.getItem('customer');
  }
  setUserAndToken(token: String, user, userroll, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = userroll;
    this.user$.next(user);
    this.ls.setItem(this.JWT_TOKEN, token);
    this.ls.setItem('user', userroll);
    // this.ls.setItem('userroll', userroll);
  }
  changePasswordAdmin(oldPassword, newPassword) {
    let user = this.getUser();
    return this.http.post(`${environment.apiURL}/api/user/resetPassword`, {
      id: user.userId,
      password: oldPassword,
      newpassword: newPassword,
    });
  }
}
