export const config = {
  // apiUrl: 'http://localhost:3000',

  //production
  apiUrl: 'https://backend.warehouse.royalgulfshipping.com',

  //staging
  //apiUrl: 'http://3.15.86.51:3000',

  //NEW staging
  //apiUrl: '  http://3.137.37.56:3000',
  authRoles: {
    sa: ['SA'], // Only Super Admin has access
    admin: ['SA', 'Admin'], // Only SA & Admin has access
    editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'], // Everyone has access
  },
};
