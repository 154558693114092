import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { InvoiceService } from '../invoice.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-password-resetadmin',
  templateUrl: './password-resetadmin.component.html',
  styleUrls: ['./password-resetadmin.component.scss'],
})
export class PasswordResetadminComponent implements OnInit {
  public itemForm: FormGroup;
  typePassword = 'password';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PasswordResetadminComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.buildItemForm();
  }
  buildItemForm() {
    this.itemForm = this.fb.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    });
  }
  submit() {
    if (this.itemForm.valid) {
      if (this.itemForm.value.newpassword == this.itemForm.value.confirmpassword) {
        this.dialogRef.close(this.itemForm.value);
      } else {
        this.snack.open('Passwords Mismatch.', 'close', { duration: 4000 });
      }
    } else {
      this.snack.open('All feilds are required.', 'close', { duration: 4000 });
    }
  }
  changePasswordText() {
    if (this.typePassword == 'password') {
      this.typePassword = 'text';
    } else {
      this.typePassword = 'password';
    }
  }
}
