import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import { ThemeService } from './services/theme.service';
import { NavigationService } from './services/navigation.service';
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './guards/auth.guard';
import { UserRoleGuard } from './guards/user-role.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';
import { AppAlertService } from './services/app-alert/app-alert.service';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { CustomerService } from 'app/views/customer/customer.service';

@NgModule({
  imports: [CommonModule, SharedComponentsModule, SharedPipesModule, SharedDirectivesModule],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    UserRoleGuard,
    AppConfirmService,
    AppLoaderService,
    AppAlertService,
    CustomerService,
  ],
  exports: [SharedComponentsModule, SharedPipesModule, SharedDirectivesModule],
})
export class SharedModule {}
